
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: { title: 'Home' },

    extends: View,

    mixins: [
      LoadSections([
        'vizion-home',
        // 'hero',
        // 'loan-process',
        // 'loan-products',
        // 'news',
        // 'contact-us',
        // 'team',
        // 'social-media',
        // 'keep-in-touch',
        // 'newsletter',
        // 'map',
        // 'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
